<template>
  <div class="main-box" style="padding: 10px 10px 40px 10px; width:100%;" v-if="info">
    <div class="flex">
      <day-template :isEdit="true" :data="content" :callFunc="changeContent" v-if="info.c_type==1"></day-template>
      <week-template :isEdit="true" :data="content" :callFunc="changeContent" v-if="info.c_type==2"></week-template>
      <month-template :isEdit="true" :data="content" :callFunc="changeContent" v-if="info.c_type==3"></month-template>
    </div>
  </div>
</template>
<script>

import WeekTemplate from "@/components/storeFour/WeekTemplate";
import DayTemplate from "@/components/storeFour/DayTemplate";
import MonthTemplate from "@/components/storeFour/MonthTemplate";
export default {
  components: {
    WeekTemplate,
    DayTemplate,
    MonthTemplate
  },
  data() {
    return {
      info: false,
      id: 0,
      source_domain: this.config.SOURCE_DOMAIN,
      content: {}
    };
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.getInfo()
    }
  },
  mounted() {
  },
  computed: {},
  methods: {
    getInfo() {
      this.$api.operate.storefourDetail({id:this.id},res => {
        if(res.errcode==0) {
          this.info = res.data
          setTimeout(res => {
            this.content = this.info
          },100)
        }
      })
    },
    changeContent(data) {
      var params = {
        id: this.id,
        data: data
      }
      this.$api.operate.storefourFinish(params, res => {
        if(res.errcode==0) {
          // this.success(res.errmsg)
        } else {
          this.fail(res.errmsg)
        }
      })
    }
  }
};
</script>
<style scoped>
</style>
