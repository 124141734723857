<template>
  <div class="box" style="padding: 0; margin: 0;" v-if="info">
    <table width="100%" class="table-xls tc bg0" style="min-width: 1100px;">
      <tr>
        <td colspan="10" class="h2 tc black">店长每月工作流程（{{info.start_date.substr(0,7)}}）</td>
      </tr>
      <tr>
        <td width="4%">序号</td>
        <td width="7%">时间</td>
        <td width="4%">段落</td>
        <td width="9%">事项</td>
        <td width="7%">区域</td>
        <td width="4%">编号</td>
        <td width="33%">动作（细则）</td>
        <td width="10%">工具</td>
        <td width="15%">备注</td>
        <td width="7%">状态</td>
      </tr>
      <tr>
        <td rowspan="5">1</td>
        <td rowspan="5">每月最后一个周日</td>
        <td rowspan="5">1</td>
        <td rowspan="5">月工作计划</td>
        <td rowspan="5">开会场地</td>
        <td>1.1</td>
        <td class="left">1.分解当月经营目标及制定提升的主要行动计划</td>
        <td rowspan="2">月计划表详见附件</td>
        <td rowspan="2"><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k11.remark" :disabled="!isEdit" @change="inputChange(content.k11)"></textarea></td>
        <td rowspan="2">
          <div v-if="content.k11.status==1">已完成<br>{{content.k11.time}}</div>
          <el-popconfirm v-else-if="isEdit"
                         title="确认完成吗？"
                         @confirm="sureDay(content.k11)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
      </tr>
      <tr>
        <td>1.2</td>
        <td class="left">2.月培训计划/员工访谈表整理/月人员调整及补充计划/当月客诉处理总结/月度库存盘点安排</td>
      </tr>
      <tr>
        <td>1.3</td>
        <td class="left">3.整理财务，确定收款准确无遗漏，填写财务总表，工资表，人力分析表，报销管理表等财务表格</td>
        <td>财务文件</td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k12.remark" :disabled="!isEdit" @change="inputChange(content.k12)"></textarea></td>
        <td>
          <div v-if="content.k12.status==1">已完成<br>{{content.k12.time}}</div>
          <el-popconfirm v-else-if="isEdit"
                         title="确认完成吗？"
                         @confirm="sureDay(content.k12)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
      </tr>
      <tr>
        <td>1.4</td>
        <td class="left">4.监督各人员提交提成记录表，提成对账工作</td>
        <td></td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k13.remark" :disabled="!isEdit" @change="inputChange(content.k13)"></textarea></td>
        <td>
          <div v-if="content.k13.status==1">已完成<br>{{content.k13.time}}</div>
          <el-popconfirm v-else-if="isEdit"
                         title="确认完成吗？"
                         @confirm="sureDay(content.k13)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
      </tr>
      <tr>
        <td>1.5</td>
        <td class="left">钉钉财务报销审核整理</td>
        <td>钉钉，报销管理表格</td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k14.remark" :disabled="!isEdit" @change="inputChange(content.k14)"></textarea></td>
        <td>
          <div v-if="content.k14.status==1">已完成<br>{{content.k14.time}}</div>
          <el-popconfirm v-else-if="isEdit"
                         title="确认完成吗？"
                         @confirm="sureDay(content.k14)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
      </tr>
      <tr>
        <td rowspan="5">2</td>
        <td rowspan="5">每月第一个周一</td>
        <td rowspan="3">2</td>
        <td rowspan="3">店内管理层月会</td>
        <td rowspan="3">开会场地</td>
        <td>2.1</td>
        <td class="left">1.组织会议，分享研究月报，以及门店全貌，总结本月情况，推出下月计划，按照门店标准自我检查一遍，月计划内容，传达公司信息</td>
        <td rowspan="3">开会记录表</td>
        <td rowspan="3"><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k21.remark" :disabled="!isEdit" @change="inputChange(content.k21)"></textarea></td>
        <td rowspan="3">
          <div v-if="content.k21.status==1">已完成<br>{{content.k21.time}}</div>
          <el-popconfirm v-else-if="isEdit"
                         title="确认完成吗？"
                         @confirm="sureDay(content.k21)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
      </tr>
      <tr>
        <td>2.2</td>
        <td class="left">2.听取各小组组长汇报工作总结和计划（业绩目标达成分析/业绩提升行动计划/上月改进项完成情况/所需支持/本月目标）</td>
      </tr>
      <tr>
        <td>2.3</td>
        <td class="left">3.对优秀员工进行表彰</td>
      </tr>
      <tr>
        <td rowspan="2">3</td>
        <td rowspan="2">月团建</td>
        <td rowspan="2">团建场地</td>
        <td>3.1</td>
        <td class="left">1.申请筹备，团建安排在月会之后</td>
        <td rowspan="2"></td>
        <td rowspan="2"><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k22.remark" :disabled="!isEdit" @change="inputChange(content.k22)"></textarea></td>
        <td rowspan="2">
          <div v-if="content.k22.status==1">已完成<br>{{content.k22.time}}</div>
          <el-popconfirm v-else-if="isEdit"
                         title="确认完成吗？"
                         @confirm="sureDay(content.k22)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
      </tr>
      <tr>
        <td>3.2</td>
        <td class="left">2.如遇员工过生日可以一起帮助庆生</td>
      </tr>
      <tr>
        <td rowspan="2">3</td>
        <td rowspan="2">发工资当日</td>
        <td rowspan="2">4</td>
        <td rowspan="2">员工一对一沟通</td>
        <td rowspan="2">开会场地</td>
        <td>4.1</td>
        <td class="left">1.完成员工月工资绩效评定及面谈</td>
        <td rowspan="2"></td>
        <td rowspan="2"><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k3.remark" :disabled="!isEdit" @change="inputChange(content.k3)"></textarea></td>
        <td rowspan="2">
          <div v-if="content.k3.status==1">已完成<br>{{content.k3.time}}</div>
          <el-popconfirm v-else-if="isEdit"
                         title="确认完成吗？"
                         @confirm="sureDay(content.k3)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
      </tr>
      <tr>
        <td>4.2</td>
        <td class="left">2.员工工资表编制<br />
          （每月5号之前统计员工出勤时间，统计出员工基本工资/统计绩效完成情况，根据绩效制度编制工资表/每月10号提交给门店老板复核，做好员工沟通）</td>
      </tr>
      <tr>
        <td rowspan="5">4</td>
        <td rowspan="5">每月10号前</td>
        <td rowspan="5">5</td>
        <td rowspan="5">对账</td>
        <td rowspan="5">前台</td>
        <td>5.1</td>
        <td class="left">1备用金对账：按照规定申请备用金（建议每店2000元）/店长为备用金唯一使用人</td>
        <td rowspan="4">备用金使用登记表详见附件15</td>
        <td rowspan="5"><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k4.remark" :disabled="!isEdit" @change="inputChange(content.k4)"></textarea></td>
        <td rowspan="5">
          <div v-if="content.k4.status==1">已完成<br>{{content.k4.time}}</div>
          <el-popconfirm v-else-if="isEdit"
                         title="确认完成吗？"
                         @confirm="sureDay(content.k4)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
      </tr>
      <tr>
        <td>5.2</td>
        <td class="left">2.备用金使用后需按票报销，如无发票需上一级领导批准使用其他发票报销,离职时需要平账</td>
      </tr>
      <tr>
        <td>5.3</td>
        <td class="left">3.（F6-财务-应收账款-更多筛选-记账协议）应收账款对账：记账管理</td>
      </tr>
      <tr>
        <td>5.4</td>
        <td class="left">4.（F6-财务-应付账款-根据交易对象生成对账单-对账列表-普通对账单/货无忧对账单）应付账款对账：协作单位/前置仓与平台供应商/货无忧</td>
      </tr>
      <tr>
        <td>5.5</td>
        <td class="left">5.危废款：每周按时清理/废品款登记在《危废收支明细表》，并上交财务</td>
        <td>危废台账详见-附件16</td>
      </tr>
      <tr>
        <td rowspan="2">5</td>
        <td rowspan="2">每月30号</td>
        <td rowspan="2">6</td>
        <td rowspan="2">门店安全管理</td>
        <td rowspan="2">各工作区域</td>
        <td>6.1</td>
        <td class="left">1.门店和仓库内消防器材由各门店负责人进行日常管理和保养，并定期进行检查：每月检查1次，并做好记录,发现隐患，及时督促整改</td>
        <td>安全检查表详见-附件17</td>
        <td rowspan="1"><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k51.remark" :disabled="!isEdit" @change="inputChange(content.k51)"></textarea></td>
        <td rowspan="1">
          <div v-if="content.k51.status==1">已完成<br>{{content.k51.time}}</div>
          <el-popconfirm v-else-if="isEdit"
                         title="确认完成吗？"
                         @confirm="sureDay(content.k51)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
      </tr>
      <tr>
        <td>6.2</td>
        <td class="left">2.店内合同规范度</td>
        <td>员工合同规范表</td>
        <td rowspan="1"><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k52.remark" :disabled="!isEdit" @change="inputChange(content.k52)"></textarea></td>
        <td rowspan="1">
          <div v-if="content.k52.status==1">已完成<br>{{content.k52.time}}</div>
          <el-popconfirm v-else-if="isEdit"
                         title="确认完成吗？"
                         @confirm="sureDay(content.k52)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
      </tr>
      <tr>
        <td rowspan="3">6</td>
        <td rowspan="3">月最后一天</td>
        <td rowspan="3">7</td>
        <td rowspan="3">库存月盘点</td>
        <td rowspan="3">仓库、各工作区域</td>
        <td>7.1</td>
        <td class="left">1.参与人员：店长、仓管共同参与</td>
        <td rowspan="3">F6系统</td>
        <td rowspan="3"><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k6.remark" :disabled="!isEdit" @change="inputChange(content.k6)"></textarea></td>
        <td rowspan="3">
          <div v-if="content.k6.status==1">已完成<br>{{content.k6.time}}</div>
          <el-popconfirm v-else-if="isEdit"
                         title="确认完成吗？"
                         @confirm="sureDay(content.k6)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
      </tr>
      <tr>
        <td>7.2</td>
        <td class="left">2.（ F6-库存-盘点-全盘-添加-确定-打印-输入盘库结果）监督仓管完成盘点内容：门店所有货品、经营性耗材和工具</td>
      </tr>
      <tr>
        <td>7.3</td>
        <td class="left">3.盘亏责任划分-按照门店具体分配责任人来承担。</td>
      </tr>
      <tr>
        <td rowspan="2">7</td>
        <td rowspan="2">不定时事项</td>
        <td>8</td>
        <td>人事工作</td>
        <td>前台</td>
        <td>8.1</td>
        <td class="left">1.入离职流程/劳动合同/请假制度</td>
        <td>人事行政工具包详见-附件19</td>
        <td rowspan="1"><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k71.remark" :disabled="!isEdit" @change="inputChange(content.k71)"></textarea></td>
        <td rowspan="1">
          <div v-if="content.k71.status==1">已完成<br>{{content.k71.time}}</div>
          <el-popconfirm v-else-if="isEdit"
                         title="确认完成吗？"
                         @confirm="sureDay(content.k71)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
      </tr>
      <tr>
        <td>9</td>
        <td>联合月会</td>
        <td>开会场地</td>
        <td>8.2</td>
        <td class="left">1.参加联合月会</td>
        <td>参见会议通知</td>
        <td rowspan="1"><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k72.remark" :disabled="!isEdit" @change="inputChange(content.k72)"></textarea></td>
        <td rowspan="1">
          <div v-if="content.k72.status==1">已完成<br>{{content.k72.time}}</div>
          <el-popconfirm v-else-if="isEdit"
                         title="确认完成吗？"
                         @confirm="sureDay(content.k72)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
      </tr>
      <tr>
        <td>8</td>
        <td>季度</td>
        <td>10</td>
        <td>员工晋升考核</td>
        <td>开会场地</td>
        <td>9.1</td>
        <td class="left">1.对符合晋升员工进行考核</td>
        <td>参见云课堂考核标准</td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k8.remark" :disabled="!isEdit" @change="inputChange(content.k8)"></textarea></td>
        <td>
          <div v-if="content.k8.status==1">已完成<br>{{content.k8.time}}</div>
          <el-popconfirm v-else-if="isEdit"
                         title="确认完成吗？"
                         @confirm="sureDay(content.k8)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>

import util from "@/utils/util";
export default {
  data() {
    return {
      info: false,
      content: {
        k11: {title:'每月最后一个周日 1.分解当月经营目标及制定提升的主要行动计划 2.月培训计划/员工访谈表整理/月人员调整及补充计划/当月客诉处理总结/月度库存盘点安排',remark:'',status:'',time:''},
        k12: {title:'每月最后一个周日 3.整理财务，确定收款准确无遗漏，填写财务总表，工资表，人力分析表，报销管理表等财务表格',remark:'',status:'',time:''},
        k13: {title:'每月最后一个周日 4.监督各人员提交提成记录表，提成对账工作',remark:'',status:'',time:''},
        k14: {title:'每月最后一个周日 钉钉财务报销审核整理',remark:'',status:'',time:''},
        k21: {title:'每月第一个周一 1 2 3',remark:'',status:'',time:''},
        k22: {title:'每月第一个周一 1.申请筹备，团建安排在月会之后 2.如遇员工过生日可以一起帮助庆生',remark:'',status:'',time:''},
        k3: {title:'发工资当日',remark:'',status:'',time:''},
        k4: {title:'每月10号前',remark:'',status:'',time:''},
        k51: {title:'每月30号 1.门店和仓库内消防器材由各门店负责人进行日常管理和保养，并定期进行检查：每月检查1次，并做好记录,发现隐患，及时督促整改',remark:'',status:'',time:''},
        k52: {title:'每月30号 2.店内合同规范度',remark:'',status:'',time:''},
        k6: {title:'月最后一天',remark:'',status:'',time:''},
        k71: {title:'不定时事项 1.入离职流程/劳动合同/请假制度',remark:'',status:'',time:''},
        k72: {title:'不定时事项 1.参加联合月会',remark:'',status:'',time:''},
        k8: {title:'季度',remark:'',status:'',time:''},
      }
    };
  },
  created() {
  },
  mounted() {
  },
  computed: {
  },
  props: {
    isEdit: Boolean,
    data: Object,
    callFunc: Function
  },
  watch: {
    data(newVal) {
      if(newVal) {
        this.info = newVal
        if(newVal.content) {
          this.content = newVal.content
        }
      }
    }
  },
  methods: {
    inputChange(item) {
      this.rsyncData()
    },
    sureDay(item) {
      item.status = 1
      item.time = util.formatTime(new Date())
      this.rsyncData()
    },
    rsyncData() {
      if(this.callFunc) {
        this.callFunc(this.content)
      }
    }
  }
};
</script>
<style scoped>
</style>
