<template>
  <div class="box" style="padding: 0; margin: 0;">
    <table width="100%" class="table-xls tc bg0" style="min-width: 1100px;">
      <tr>
        <td colspan="11" class="h2 tc black">店长每日工作流程（{{info.start_date}}）</td>
      </tr>
      <tr>
        <td width="4%">序号</td>
        <td width="7%">时间</td>
        <td width="4%">段落</td>
        <td width="9%">事项</td>
        <td width="4%">编号</td>
        <td width="7%">区域</td>
        <td width="4%">编号</td>
        <td width="33%">动作（细则）</td>
        <td width="10%">工具</td>
        <td width="7%">完成情况</td>
        <td width="11%">备注</td>
      </tr>
      <tr>
        <td rowspan="13">1</td>
        <td rowspan="13">9:50-10:10</td>
        <td rowspan="13">1</td>
        <td rowspan="13">营业前 检查</td>
        <td>1.1</td>
        <td>正门</td>
        <td>1.1.1</td>
        <td class="left">门窗有无撬动痕迹如（如有拍照）打开前门进入前台区域</td>
        <td rowspan="2">详细见门店开业标准-附件1</td>
        <td>
          <div v-if="content.k11.status==1">已完成<br>{{content.k11.time}}</div>
          <el-popconfirm
             v-else-if="isEdit"
             title="确认完成吗？"
             @confirm="sureDay(content.k11)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k11.remark" :disabled="!isEdit" @change="inputChange(content.k11)"></textarea></td>
      </tr>
      <tr>
        <td>1.2</td>
        <td>公共区域</td>
        <td>1.2.1</td>
        <td class="left">打开公共区域灯光</td>
        <td>
          <div v-if="content.k12.status==1">已完成<br>{{content.k12.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k12)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k12.remark" :disabled="!isEdit" @change="inputChange(content.k12)"></textarea></td>
      </tr>
      <tr>
        <td>1.3</td>
        <td>通道以及停车场</td>
        <td>1.3.1</td>
        <td class="left">检查并移走阻碍通道车辆（保持动线通畅），停车场车辆摆放是否整齐，是否存在外来车辆（参考运营手册停车场管理）</td>
        <td>参考附件停车场管理</td>
        <td>
          <div v-if="content.k13.status==1">已完成<br>{{content.k13.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k13)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k13.remark" :disabled="!isEdit" @change="inputChange(content.k13)"></textarea></td>
      </tr>
      <tr>
        <td rowspan="3">1.4</td>
        <td rowspan="3">会客区</td>
        <td>1.4.1</td>
        <td class="left">会客区是否干净整洁（参考运营手册会客区标准）</td>
        <td>详细参考运营手册会客区标准</td>
        <td>
          <div v-if="content.k14.status==1">已完成<br>{{content.k14.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k14)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k14.remark" :disabled="!isEdit" @change="inputChange(content.k14)"></textarea></td>
      </tr>
      <tr>
        <td>1.4.2</td>
        <td class="left">电脑财务表格，后台质保系统。打开</td>
        <td></td>
        <td>
          <div v-if="content.k15.status==1">已完成<br>{{content.k15.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k15)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k15.remark" :disabled="!isEdit" @change="inputChange(content.k15)"></textarea></td>
      </tr>
      <tr>
        <td>1.4.3</td>
        <td class="left">收银码,大众店铺卡,车主社群卡。摆放整齐</td>
        <td></td>
        <td>
          <div v-if="content.k16.status==1">已完成<br>{{content.k16.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k16)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k16.remark" :disabled="!isEdit" @change="inputChange(content.k16)"></textarea></td>
      </tr>
      <tr>
        <td rowspan="4">1.5</td>
        <td rowspan="4">综合工位</td>
        <td>1.5.1</td>
        <td class="left">灯光正常开启，水电气正常</td>
        <td></td>
        <td>
          <div v-if="content.k17.status==1">已完成<br>{{content.k17.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k17)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k17.remark" :disabled="!isEdit" @change="inputChange(content.k17)"></textarea></td>
      </tr>
      <tr>
        <td>1.5.2</td>
        <td class="left">检查：洗车区域水桶和小推车摆放整齐，洗车毛巾干净干燥，洗车药剂充足</td>
        <td></td>
        <td>
          <div v-if="content.k18.status==1">已完成<br>{{content.k18.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k18)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k18.remark" :disabled="!isEdit" @change="inputChange(content.k18)"></textarea></td>
      </tr>
      <tr>
        <td>1.5.3</td>
        <td class="left">F6-订单-预约管理-预约看板-检查预约车辆的技师和工位安排</td>
        <td>F6</td>
        <td>
          <div v-if="content.k19.status==1">已完成<br>{{content.k19.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k19)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k19.remark" :disabled="!isEdit" @change="inputChange(content.k19)"></textarea></td>
      </tr>
      <tr>
        <td>1.5.4</td>
        <td class="left">快递物流等拆开，膜类归位（参考膜类库存管理）</td>
        <td></td>
        <td>
          <div v-if="content.k110.status==1">已完成<br>{{content.k110.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k110)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k110.remark" :disabled="!isEdit" @change="inputChange(content.k110)"></textarea></td>
      </tr>
      <tr>
        <td rowspan="3">1.6</td>
        <td rowspan="3">人员</td>
        <td>1.6.1</td>
        <td class="left">人员到位，销售就位，迟到的人提醒</td>
        <td></td>
        <td>
          <div v-if="content.k111.status==1">已完成<br>{{content.k111.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k111)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k111.remark" :disabled="!isEdit" @change="inputChange(content.k111)"></textarea></td>
      </tr>
      <tr>
        <td>1.6.2</td>
        <td class="left">人员是否处于工作状态，无抽烟吃早餐等现象</td>
        <td></td>
        <td>
          <div v-if="content.k112.status==1">已完成<br>{{content.k112.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k112)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k112.remark" :disabled="!isEdit" @change="inputChange(content.k112)"></textarea></td>
      </tr>
      <tr>
        <td>1.6.3</td>
        <td class="left">根据蓝电员工形象标准进行检查</td>
        <td>蓝电员工形象标准</td>
        <td>
          <div v-if="content.k113.status==1">已完成<br>{{content.k113.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k113)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k113.remark" :disabled="!isEdit" @change="inputChange(content.k113)"></textarea></td>
      </tr>
      <tr>
        <td rowspan="4">2</td>
        <td rowspan="4">10:10-10:20</td>
        <td rowspan="4">2</td>
        <td rowspan="4">晨会</td>
        <td rowspan="4">2.1</td>
        <td rowspan="4">办公室</td>
        <td>2.1.1</td>
        <td class="left">检查产品专家对车主群、美团、抖音、小红书等第三方信息回复和整理</td>
        <td></td>
        <td>
          <div v-if="content.k21.status==1">已完成<br>{{content.k21.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k21)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k21.remark" :disabled="!isEdit" @change="inputChange(content.k21)"></textarea></td>
      </tr>
      <tr>
        <td>2.1.2</td>
        <td class="left">特殊事务发言，如：介绍新员工，员工表扬</td>
        <td></td>
        <td>
          <div v-if="content.k22.status==1">已完成<br>{{content.k22.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k22)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k22.remark" :disabled="!isEdit" @change="inputChange(content.k22)"></textarea></td>
      </tr>
      <tr>
        <td>2.1.3</td>
        <td class="left">询问产品专家是否有问题回馈</td>
        <td></td>
        <td>
          <div v-if="content.k23.status==1">已完成<br>{{content.k23.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k23)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k23.remark" :disabled="!isEdit" @change="inputChange(content.k23)"></textarea></td>
      </tr>
      <tr>
        <td>2.1.4</td>
        <td class="left">安排夜晚的清洁分工</td>
        <td></td>
        <td>
          <div v-if="content.k24.status==1">已完成<br>{{content.k24.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k24)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k24.remark" :disabled="!isEdit" @change="inputChange(content.k24)"></textarea></td>
      </tr>
      <tr>
        <td rowspan="4">3</td>
        <td rowspan="4">10:10-10:30</td>
        <td rowspan="4">3</td>
        <td rowspan="4">巡检</td>
        <td rowspan="4">3.1</td>
        <td rowspan="4">各区域</td>
        <td>3.1.1</td>
        <td class="left">门店日常5S巡检，用手机打开万店掌APP-现场巡店-5S巡检--贴膜中心店巡检模板</td>
        <td></td>
        <td>
          <div v-if="content.k31.status==1">已完成<br>{{content.k31.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k31)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k31.remark" :disabled="!isEdit" @change="inputChange(content.k31)"></textarea></td>
      </tr>
      <tr>
        <td>3.1.2</td>
        <td class="left">检查产品专家在轮班站岗,客户引导及负责接待客户</td>
        <td></td>
        <td>
          <div v-if="content.k32.status==1">已完成<br>{{content.k32.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k32)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k32.remark" :disabled="!isEdit" @change="inputChange(content.k32)"></textarea></td>
      </tr>
      <tr>
        <td>3.1.3</td>
        <td class="left">检查技师在各工位区责任区域的5S点检，隐患上报并及时整改</td>
        <td></td>
        <td>
          <div v-if="content.k33.status==1">已完成<br>{{content.k33.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k33)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k33.remark" :disabled="!isEdit" @change="inputChange(content.k33)"></textarea></td>
      </tr>
      <tr>
        <td>3.1.4</td>
        <td class="left">检查产品墙/设备/膜类展示情况</td>
        <td></td>
        <td>
          <div v-if="content.k34.status==1">已完成<br>{{content.k34.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k34)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k34.remark" :disabled="!isEdit" @change="inputChange(content.k34)"></textarea></td>
      </tr>
      <tr>
        <td rowspan="6">4</td>
        <td rowspan="6">10:30-11:30</td>
        <td rowspan="6">4</td>
        <td rowspan="6">日常事物协调处理</td>
        <td rowspan="6">4.1</td>
        <td rowspan="6">各工作区域</td>
        <td>4.1.1</td>
        <td class="left">查看并回复客户信息</td>
        <td>微信</td>
        <td>
          <div v-if="content.k41.status==1">已完成<br>{{content.k41.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k41)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k41.remark" :disabled="!isEdit" @change="inputChange(content.k41)"></textarea></td>
      </tr>
      <tr>
        <td>4.1.2</td>
        <td class="left">监督产品专家按时交车：跟踪车辆施工，避免等待时间过长</td>
        <td>F6-到店列表</td>
        <td>
          <div v-if="content.k42.status==1">已完成<br>{{content.k42.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k42)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k42.remark" :disabled="!isEdit" @change="inputChange(content.k42)"></textarea></td>
      </tr>
      <tr>
        <td>4.1.3</td>
        <td class="left">根据门店运营情况合理协调岗位/补岗（巡视各区域情况）</td>
        <td>工作群</td>
        <td>
          <div v-if="content.k43.status==1">已完成<br>{{content.k43.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k43)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k43.remark" :disabled="!isEdit" @change="inputChange(content.k43)"></textarea></td>
      </tr>
      <tr>
        <td>4.1.4</td>
        <td class="left">按照万店掌流程巡检/检查技师SOP施工流程</td>
        <td>万店掌-流程巡检</td>
        <td>
          <div v-if="content.k44.status==1">已完成<br>{{content.k44.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k44)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k44.remark" :disabled="!isEdit" @change="inputChange(content.k44)"></textarea></td>
      </tr>
      <tr>
        <td>4.1.5</td>
        <td class="left">突发事件的处理（客户投诉及员工冲突,设备故障等）</td>
        <td></td>
        <td>
          <div v-if="content.k45.status==1">已完成<br>{{content.k45.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k45)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k45.remark" :disabled="!isEdit" @change="inputChange(content.k45)"></textarea></td>
      </tr>
      <tr>
        <td>4.1.6</td>
        <td class="left">监督产品专家是否积极主动上前询问新到店客户，安排接待或者转交</td>
        <td></td>
        <td>
          <div v-if="content.k46.status==1">已完成<br>{{content.k46.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k46)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k46.remark" :disabled="!isEdit" @change="inputChange(content.k46)"></textarea></td>
      </tr>
      <tr>
        <td rowspan="3">5</td>
        <td rowspan="3">11:30-13:30</td>
        <td rowspan="3">5</td>
        <td rowspan="3">换班就餐</td>
        <td rowspan="3">5.1</td>
        <td rowspan="3">员工休息区</td>
        <td>5.1.1</td>
        <td class="left">每人有30分钟就餐</td>
        <td></td>
        <td>
          <div v-if="content.k51.status==1">已完成<br>{{content.k51.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k51)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k51.remark" :disabled="!isEdit" @change="inputChange(content.k51)"></textarea></td>
      </tr>
      <tr>
        <td>5.1.2</td>
        <td class="left">检查员工在规范时间内完成午餐，不建议外出就餐！</td>
        <td></td>
        <td>
          <div v-if="content.k52.status==1">已完成<br>{{content.k52.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k52)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k52.remark" :disabled="!isEdit" @change="inputChange(content.k52)"></textarea></td>
      </tr>
      <tr>
        <td>5.1.3</td>
        <td class="left">同岗位不可同时就餐，不可出现空岗</td>
        <td></td>
        <td>
          <div v-if="content.k53.status==1">已完成<br>{{content.k53.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k53)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k53.remark" :disabled="!isEdit" @change="inputChange(content.k53)"></textarea></td>
      </tr>
      <tr>
        <td rowspan="10">6</td>
        <td>13:30-14:00</td>
        <td>6</td>
        <td>巡检</td>
        <td>6.1</td>
        <td>店内</td>
        <td>6.1.1</td>
        <td class="left">下午巡检1次门店日常5S及流程巡检，用手机打开万店掌APP-现场巡店-5S/流程巡检</td>
        <td></td>
        <td>
          <div v-if="content.k61.status==1">已完成<br>{{content.k61.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k61)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k61.remark" :disabled="!isEdit" @change="inputChange(content.k61)"></textarea></td>
      </tr>
      <tr>
        <td rowspan="9">14:00-17:45</td>
        <td rowspan="3">7</td>
        <td rowspan="3">客户管理</td>
        <td rowspan="3">7.1</td>
        <td rowspan="3">产品专家</td>
        <td>7.1.1</td>
        <td class="left">按店长接待规范执行新客户到店流程</td>
        <td></td>
        <td>
          <div v-if="content.k62.status==1">已完成<br>{{content.k62.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k62)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k62.remark" :disabled="!isEdit" @change="inputChange(content.k62)"></textarea></td>
      </tr>
      <tr>
        <td>7.1.2</td>
        <td class="left">辅导员工销售项目过程协助促单成交</td>
        <td></td>
        <td>
          <div v-if="content.k63.status==1">已完成<br>{{content.k63.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k63)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k63.remark" :disabled="!isEdit" @change="inputChange(content.k63)"></textarea></td>
      </tr>
      <tr>
        <td>7.1.3</td>
        <td class="left">亲自执行1次接、交车全流程动作:</td>
        <td></td>
        <td>
          <div v-if="content.k64.status==1">已完成<br>{{content.k64.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k64)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k64.remark" :disabled="!isEdit" @change="inputChange(content.k64)"></textarea></td>
      </tr>
      <tr>
        <td rowspan="6">8</td>
        <td rowspan="6">日常事物协调处理</td>
        <td rowspan="6">8.1</td>
        <td rowspan="6">各工作区域</td>
        <td>8.1.1</td>
        <td class="left">积极查看并回复车主信息</td>
        <td>钉钉</td>
        <td>
          <div v-if="content.k65.status==1">已完成<br>{{content.k65.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k65)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k65.remark" :disabled="!isEdit" @change="inputChange(content.k65)"></textarea></td>
      </tr>
      <tr>
        <td>8.1.2</td>
        <td class="left">检查产品专家车主群信息、美团等第三方回复和整理</td>
        <td>微信群</td>
        <td>
          <div v-if="content.k66.status==1">已完成<br>{{content.k66.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k66)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k66.remark" :disabled="!isEdit" @change="inputChange(content.k66)"></textarea></td>
      </tr>
      <tr>
        <td>8.1.3</td>
        <td class="left">监督店内施工车辆是否正常，施工进度是否达标，检查工单</td>
        <td>F6-到店列表</td>
        <td>
          <div v-if="content.k67.status==1">已完成<br>{{content.k67.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k67)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k67.remark" :disabled="!isEdit" @change="inputChange(content.k67)"></textarea></td>
      </tr>
      <tr>
        <td>8.1.4</td>
        <td class="left">根据门店运营情况合理协调岗位/补岗（巡视各区域业务情况）</td>
        <td>微信群</td>
        <td>
          <div v-if="content.k68.status==1">已完成<br>{{content.k68.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k68)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k68.remark" :disabled="!isEdit" @change="inputChange(content.k68)"></textarea></td>
      </tr>
      <tr>
        <td>8.1.5</td>
        <td class="left">门店运营突发事件的处理（客户投诉及员工冲突,设备故障等）</td>
        <td></td>
        <td>
          <div v-if="content.k69.status==1">已完成<br>{{content.k69.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k69)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k69.remark" :disabled="!isEdit" @change="inputChange(content.k69)"></textarea></td>
      </tr>
      <tr>
        <td>8.1.6</td>
        <td class="left">完成每周工作列表</td>
        <td></td>
        <td>
          <div v-if="content.k610.status==1">已完成<br>{{content.k610.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k610)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k610.remark" :disabled="!isEdit" @change="inputChange(content.k610)"></textarea></td>
      </tr>
      <tr>
        <td rowspan="3">7</td>
        <td rowspan="3">17:45-18:00</td>
        <td rowspan="3">9</td>
        <td rowspan="3">确认库存情况和排程情况</td>
        <td rowspan="3">9.1</td>
        <td rowspan="2">仓库</td>
        <td>9.1.1</td>
        <td class="left">改件的库存更新，根据库存表及时补货（使用库存盘点工具）</td>
        <td></td>
        <td>
          <div v-if="content.k71.status==1">已完成<br>{{content.k71.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k71)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k71.remark" :disabled="!isEdit" @change="inputChange(content.k71)"></textarea></td>
      </tr>
      <tr>
        <td>9.1.2</td>
        <td class="left">确认明日排程的膜料是否已经下单，排程客户就位情况，</td>
        <td></td>
        <td>
          <div v-if="content.k72.status==1">已完成<br>{{content.k72.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k72)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k72.remark" :disabled="!isEdit" @change="inputChange(content.k72)"></textarea></td>
      </tr>
      <tr>
        <td>前台</td>
        <td>9.1.3</td>
        <td class="left">检查滞留车辆登记规范</td>
        <td>工作交接记录表-附件6</td>
        <td>
          <div v-if="content.k73.status==1">已完成<br>{{content.k73.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k73)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k73.remark" :disabled="!isEdit" @change="inputChange(content.k73)"></textarea></td>
      </tr>
      <tr>
        <td rowspan="3">8</td>
        <td rowspan="3">18:00-18:30</td>
        <td rowspan="3">10</td>
        <td rowspan="3">换班就餐</td>
        <td rowspan="3">10.1</td>
        <td rowspan="3">员工休息区</td>
        <td>10.1.1</td>
        <td class="left">每人有30分钟就餐</td>
        <td></td>
        <td>
          <div v-if="content.k81.status==1">已完成<br>{{content.k81.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k81)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k81.remark" :disabled="!isEdit" @change="inputChange(content.k81)"></textarea></td>
      </tr>
      <tr>
        <td>10.1.2</td>
        <td class="left">检查员工在规范时间内完成晚餐，不建议外出就餐！</td>
        <td></td>
        <td>
          <div v-if="content.k82.status==1">已完成<br>{{content.k82.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k82)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k82.remark" :disabled="!isEdit" @change="inputChange(content.k82)"></textarea></td>
      </tr>
      <tr>
        <td>10.1.3</td>
        <td class="left">同岗位不可同时就餐，不可出现空岗</td>
        <td></td>
        <td>
          <div v-if="content.k83.status==1">已完成<br>{{content.k83.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k83)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k83.remark" :disabled="!isEdit" @change="inputChange(content.k83)"></textarea></td>
      </tr>
      <tr>
        <td rowspan="11">9</td>
        <td rowspan="11">APP时间闭店</td>
        <td>11</td>
        <td>打烊检查</td>
        <td>11.1</td>
        <td>各工作区域</td>
        <td>11.1.1</td>
        <td class="left">检查各岗位是否按照打烊规范执行，并且发送图片到工作群</td>
        <td>门店打烊检查表-附件8</td>
        <td>
          <div v-if="content.k91.status==1">已完成<br>{{content.k91.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k91)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k91.remark" :disabled="!isEdit" @change="inputChange(content.k91)"></textarea></td>
      </tr>
      <tr>
        <td rowspan="9">12</td>
        <td rowspan="9">卫生检查</td>
        <td rowspan="2">12.1</td>
        <td rowspan="2">综合工位</td>
        <td>12.1.1</td>
        <td class="left">洗车区域水桶和小推车摆放整齐，洗车毛巾干净干燥（参考洗车工位标准）</td>
        <td></td>
        <td>
          <div v-if="content.k92.status==1">已完成<br>{{content.k92.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k92)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k92.remark" :disabled="!isEdit" @change="inputChange(content.k92)"></textarea></td>
      </tr>
      <tr>
        <td>12.1.2</td>
        <td class="left">气泵安排排水关闭电源，举升机放气关闭电源（检查放气表）</td>
        <td></td>
        <td>
          <div v-if="content.k93.status==1">已完成<br>{{content.k93.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k93)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k93.remark" :disabled="!isEdit" @change="inputChange(content.k93)"></textarea></td>
      </tr>
      <tr>
        <td rowspan="5">12.2</td>
        <td rowspan="5">会客区</td>
        <td>12.2.1</td>
        <td class="left">色卡，桌椅，销售工具，展示样品摆放整齐</td>
        <td></td>
        <td>
          <div v-if="content.k94.status==1">已完成<br>{{content.k94.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k94)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k94.remark" :disabled="!isEdit" @change="inputChange(content.k94)"></textarea></td>
      </tr>
      <tr>
        <td>12.2.2</td>
        <td class="left">安排扫地，倾倒垃圾，擦桌面，水池摆放干净（地面无尘，桌面无污无尘）</td>
        <td></td>
        <td>
          <div v-if="content.k95.status==1">已完成<br>{{content.k95.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k95)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k95.remark" :disabled="!isEdit" @change="inputChange(content.k95)"></textarea></td>
      </tr>
      <tr>
        <td>12.2.3</td>
        <td class="left">水杯数量补充，备用水是否充足</td>
        <td></td>
        <td>
          <div v-if="content.k96.status==1">已完成<br>{{content.k96.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k96)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k96.remark" :disabled="!isEdit" @change="inputChange(content.k96)"></textarea></td>
      </tr>
      <tr>
        <td>12.2.4</td>
        <td class="left">收银桌桌面上是否干净整洁，是否只存在工作工具</td>
        <td></td>
        <td>
          <div v-if="content.k97.status==1">已完成<br>{{content.k97.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k97)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k97.remark" :disabled="!isEdit" @change="inputChange(content.k97)"></textarea></td>
      </tr>
      <tr>
        <td>12.2.5</td>
        <td class="left">拍一张大环境的图发在群里（玻璃门角度朝里拍）</td>
        <td></td>
        <td>
          <div v-if="content.k98.status==1">已完成<br>{{content.k98.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k98)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k98.remark" :disabled="!isEdit" @change="inputChange(content.k98)"></textarea></td>
      </tr>
      <tr>
        <td rowspan="2">12.3</td>
        <td rowspan="2">门店外部</td>
        <td>12.3.1</td>
        <td class="left">车辆摆放整齐，雪糕筒归位</td>
        <td></td>
        <td>
          <div v-if="content.k99.status==1">已完成<br>{{content.k99.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k99)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k99.remark" :disabled="!isEdit" @change="inputChange(content.k99)"></textarea></td>
      </tr>
      <tr>
        <td>12.3.2</td>
        <td class="left">外部小仓库上锁</td>
        <td></td>
        <td>
          <div v-if="content.k910.status==1">已完成<br>{{content.k910.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k910)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k910.remark" :disabled="!isEdit" @change="inputChange(content.k910)"></textarea></td>
      </tr>
      <tr>
        <td>13</td>
        <td>排程检查</td>
        <td>13.1</td>
        <td>客户业务</td>
        <td>13.4.1</td>
        <td class="left">核对排程，确认客户到店情况</td>
        <td></td>
        <td>
          <div v-if="content.k911.status==1">已完成<br>{{content.k911.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k911)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k911.remark" :disabled="!isEdit" @change="inputChange(content.k911)"></textarea></td>
      </tr>
      <tr>
        <td rowspan="2">10</td>
        <td rowspan="2">20:00-20:10</td>
        <td rowspan="2">14</td>
        <td rowspan="2">召开夕会</td>
        <td rowspan="2">14.1</td>
        <td rowspan="2">办公室</td>
        <td>14.1.1</td>
        <td class="left">总结当日工作情况</td>
        <td>夕会模板详见-附件7</td>
        <td>
          <div v-if="content.k101.status==1">已完成<br>{{content.k101.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k101)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k101.remark" :disabled="!isEdit" @change="inputChange(content.k101)"></textarea></td>
      </tr>
      <tr>
        <td>14.1.2</td>
        <td class="left">公司通知：岗位的变动，工作任务的发布等</td>
        <td></td>
        <td>
          <div v-if="content.k102.status==1">已完成<br>{{content.k102.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k102)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k102.remark" :disabled="!isEdit" @change="inputChange(content.k102)"></textarea></td>
      </tr>
      <tr>
        <td rowspan="4">11</td>
        <td rowspan="4">20:10-20:20</td>
        <td rowspan="4">15</td>
        <td rowspan="4">发送日报</td>
        <td rowspan="4">15.1</td>
        <td rowspan="4">店长前台</td>
        <td>15.1.1</td>
        <td class="left">检查有无未结算工单，检查财务到账以及财务表格</td>
        <td>F6-工单-结算状态-未结算工单</td>
        <td>
          <div v-if="content.k1101.status==1">已完成<br>{{content.k1101.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k1101)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k1101.remark" :disabled="!isEdit" @change="inputChange(content.k1101)"></textarea></td>
      </tr>
      <tr>
        <td>15.1.2</td>
        <td class="left">导出日报标准数据，汇总到对应日报模板中</td>
        <td>店长日报模板详见-附件9</td>
        <td>
          <div v-if="content.k1102.status==1">已完成<br>{{content.k1102.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k1102)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k1102.remark" :disabled="!isEdit" @change="inputChange(content.k1102)"></textarea></td>
      </tr>
      <tr>
        <td>15.1.3</td>
        <td class="left">发送：截图发送到门店管理群中</td>
        <td></td>
        <td>
          <div v-if="content.k1103.status==1">已完成<br>{{content.k1103.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k1103)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k1103.remark" :disabled="!isEdit" @change="inputChange(content.k1103)"></textarea></td>
      </tr>
      <tr>
        <td>15.1.4</td>
        <td class="left">关闭电脑</td>
        <td></td>
        <td>
          <div v-if="content.k1104.status==1">已完成<br>{{content.k1104.time}}</div>
          <el-popconfirm
              v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k1104)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k1104.remark" :disabled="!isEdit" @change="inputChange(content.k1104)"></textarea></td>
      </tr>
    </table>
  </div>
</template>
<script>

import util from "@/utils/util";
export default {
  data() {
    return {
      info: false,
      content: {
        k11: {title:'营业前 检查 门窗有无撬动痕迹如（如有拍照）打开前门进入前台区域',remark:'',status:'',time:''},
        k12: {title:'营业前 检查 打开公共区域灯光',remark:'',status:'',time:''},
        k13: {title:'营业前 检查 检查并移走阻碍通道车辆（保持动线通畅），停车场车辆摆放是否整齐，是否存在外来车辆（参考运营手册停车场管理）',remark:'',status:'',time:''},
        k14: {title:'营业前 检查 会客区是否干净整洁（参考运营手册会客区标准）',remark:'',status:'',time:''},
        k15: {title:'营业前 检查 电脑财务表格，后台质保系统。打开',remark:'',status:'',time:''},
        k16: {title:'营业前 检查 收银码,大众店铺卡,车主社群卡。摆放整齐',remark:'',status:'',time:''},
        k17: {title:'营业前 检查 灯光正常开启，水电气正常',remark:'',status:'',time:''},
        k18: {title:'营业前 检查 检查：洗车区域水桶和小推车摆放整齐，洗车毛巾干净干燥，洗车药剂充足',remark:'',status:'',time:''},
        k19: {title:'营业前 检查 F6-订单-预约管理-预约看板-检查预约车辆的技师和工位安排',remark:'',status:'',time:''},
        k110: {title:'营业前 检查 快递物流等拆开，膜类归位（参考膜类库存管理）',remark:'',status:'',time:''},
        k111: {title:'营业前 检查 人员到位，销售就位，迟到的人提醒',remark:'',status:'',time:''},
        k112: {title:'营业前 检查 人员是否处于工作状态，无抽烟吃早餐等现象',remark:'',status:'',time:''},
        k113: {title:'营业前 检查 根据蓝电员工形象标准进行检查',remark:'',status:'',time:''},
        k21: {title:'晨会 检查产品专家对车主群、美团、抖音、小红书等第三方信息回复和整理',remark:'',status:'',time:''},
        k22: {title:'晨会 特殊事务发言，如：介绍新员工，员工表扬',remark:'',status:'',time:''},
        k23: {title:'晨会 询问产品专家是否有问题回馈',remark:'',status:'',time:''},
        k24: {title:'晨会 安排夜晚的清洁分工',remark:'',status:'',time:''},
        k31: {title:'巡检 门店日常5S巡检，用手机打开万店掌APP-现场巡店-5S巡检--贴膜中心店巡检模板',remark:'',status:'',time:''},
        k32: {title:'巡检 检查产品专家在轮班站岗,客户引导及负责接待客户',remark:'',status:'',time:''},
        k33: {title:'巡检 检查技师在各工位区责任区域的5S点检，隐患上报并及时整改',remark:'',status:'',time:''},
        k34: {title:'巡检 检查产品墙/设备/膜类展示情况',remark:'',status:'',time:''},
        k41: {title:'日常事物协调处理 查看并回复客户信息',remark:'',status:'',time:''},
        k42: {title:'日常事物协调处理 监督产品专家按时交车：跟踪车辆施工，避免等待时间过长',remark:'',status:'',time:''},
        k43: {title:'日常事物协调处理 根据门店运营情况合理协调岗位/补岗（巡视各区域情况）',remark:'',status:'',time:''},
        k44: {title:'日常事物协调处理 按照万店掌流程巡检/检查技师SOP施工流程',remark:'',status:'',time:''},
        k45: {title:'日常事物协调处理 突发事件的处理（客户投诉及员工冲突,设备故障等）',remark:'',status:'',time:''},
        k46: {title:'日常事物协调处理 监督产品专家是否积极主动上前询问新到店客户，安排接待或者转交',remark:'',status:'',time:''},
        k51: {title:'换班就餐 每人有30分钟就餐',remark:'',status:'',time:''},
        k52: {title:'换班就餐 检查员工在规范时间内完成午餐，不建议外出就餐！',remark:'',status:'',time:''},
        k53: {title:'换班就餐 同岗位不可同时就餐，不可出现空岗',remark:'',status:'',time:''},
        k61: {title:'巡检 下午巡检1次门店日常5S及流程巡检，用手机打开万店掌APP-现场巡店-5S/流程巡检',remark:'',status:'',time:''},
        k62: {title:'客户管理 按店长接待规范执行新客户到店流程',remark:'',status:'',time:''},
        k63: {title:'客户管理 辅导员工销售项目过程协助促单成交',remark:'',status:'',time:''},
        k64: {title:'客户管理 亲自执行1次接、交车全流程动作:',remark:'',status:'',time:''},
        k65: {title:'日常事物协调处理 积极查看并回复车主信息',remark:'',status:'',time:''},
        k66: {title:'日常事物协调处理 检查产品专家车主群信息、美团等第三方回复和整理',remark:'',status:'',time:''},
        k67: {title:'日常事物协调处理 监督店内施工车辆是否正常，施工进度是否达标，检查工单',remark:'',status:'',time:''},
        k68: {title:'日常事物协调处理 根据门店运营情况合理协调岗位/补岗（巡视各区域业务情况）',remark:'',status:'',time:''},
        k69: {title:'日常事物协调处理 门店运营突发事件的处理（客户投诉及员工冲突,设备故障等）',remark:'',status:'',time:''},
        k610: {title:'日常事物协调处理 完成每周工作列表',remark:'',status:'',time:''},
        k71: {title:'确认库存情况和排程情况 改件的库存更新，根据库存表及时补货（使用库存盘点工具）',remark:'',status:'',time:''},
        k72: {title:'确认库存情况和排程情况 确认明日排程的膜料是否已经下单，排程客户就位情况',remark:'',status:'',time:''},
        k73: {title:'确认库存情况和排程情况 检查滞留车辆登记规范',remark:'',status:'',time:''},
        k81: {title:'换班就餐 每人有30分钟就餐',remark:'',status:'',time:''},
        k82: {title:'换班就餐 检查员工在规范时间内完成晚餐，不建议外出就餐！',remark:'',status:'',time:''},
        k83: {title:'换班就餐 同岗位不可同时就餐，不可出现空岗',remark:'',status:'',time:''},
        k91: {title:'APP时间闭店 检查各岗位是否按照打烊规范执行，并且发送图片到工作群',remark:'',status:'',time:''},
        k92: {title:'APP时间闭店 洗车区域水桶和小推车摆放整齐，洗车毛巾干净干燥（参考洗车工位标准）',remark:'',status:'',time:''},
        k93: {title:'APP时间闭店 气泵安排排水关闭电源，举升机放气关闭电源（检查放气表）',remark:'',status:'',time:''},
        k94: {title:'APP时间闭店 色卡，桌椅，销售工具，展示样品摆放整齐',remark:'',status:'',time:''},
        k95: {title:'APP时间闭店 安排扫地，倾倒垃圾，擦桌面，水池摆放干净（地面无尘，桌面无污无尘）',remark:'',status:'',time:''},
        k96: {title:'APP时间闭店 水杯数量补充，备用水是否充足',remark:'',status:'',time:''},
        k97: {title:'APP时间闭店 收银桌桌面上是否干净整洁，是否只存在工作工具',remark:'',status:'',time:''},
        k98: {title:'APP时间闭店 拍一张大环境的图发在群里（玻璃门角度朝里拍）',remark:'',status:'',time:''},
        k99: {title:'APP时间闭店 车辆摆放整齐，雪糕筒归位',remark:'',status:'',time:''},
        k910: {title:'APP时间闭店 外部小仓库上锁',remark:'',status:'',time:''},
        k911: {title:'APP时间闭店 核对排程，确认客户到店情况',remark:'',status:'',time:''},
        k101: {title:'召开夕会 总结当日工作情况',remark:'',status:'',time:''},
        k102: {title:'召开夕会 公司通知：岗位的变动，工作任务的发布等',remark:'',status:'',time:''},
        k1101: {title:'发送日报 检查有无未结算工单，检查财务到账以及财务表格',remark:'',status:'',time:''},
        k1102: {title:'发送日报 导出日报标准数据，汇总到对应日报模板中',remark:'',status:'',time:''},
        k1103: {title:'发送日报 发送：截图发送到门店管理群中',remark:'',status:'',time:''},
        k1104: {title:'发送日报 关闭电脑',remark:'',status:'',time:''},
      }
    };
  },
  created() {
  },
  mounted() {
  },
  computed: {
  },
  props: {
    isEdit: Boolean,
    data: Object,
    callFunc: Function
  },
  watch: {
    data(newVal) {
      if(newVal) {
        this.info = newVal
        if(newVal.content) {
          this.content = newVal.content
        }
      }
    }
  },
  methods: {
    inputChange(item) {
      this.rsyncData()
    },
    sureDay(item) {
      item.status = 1
      item.time = util.formatTime(new Date())
      this.rsyncData()
    },
    rsyncData() {
      if(this.callFunc) {
        this.callFunc(this.content)
      }
    }
  }
};
</script>
<style scoped>
</style>
